<template>
    <chart-of-account-wrapper>
        <template v-slot:child>
            <div class="w-90 center mt4">
                <section class="secPad">
                    <div class="flex justify-between items-center secFirst">
                        <div></div>
                        <div></div>
                        <div>
                            <button class="flex items-center" style="gap: 10px">
                                <span><img :src="require('@/assets/images/chartCircle.svg')" /></span><span>Save changes</span>
                            </button>
                        </div>
                    </div>
                </section>
                <section class="secPad" >
                    <div class="flex justify-between items-center secFirst pad816">
                        <div class="w-30-l w-100 pad816 mbNone"></div>
                        <div class="w-30-l w-100 pad816" style="white-space: nowrap">Opening Balance (NGN)</div>
                        <div class="w-30-l w-100 pad816  mbNone"></div>
                    </div>

                    <div class="flex justify-between items-center pad816">
                        <div class="w-30 pad816 b">Account</div>
                        <div class="w-30 pad816">Debit (Dr.)</div>
                        <div class="w-30 pad816">Credit (Cr.)</div>
                    </div>

                    <div class="flex justify-between items-center pad816">
                        <div class="w-30 pad816"></div>
                        <div class="w-30 pad816">₦0.00</div>
                        <div class="w-30 pad816">₦0.00</div>
                    </div>
                </section>
                <section class="secPad" >
                    <div class="flex justify-between items-center pad816">
                        <div class="w-30 pad816 b" style="color: #132c8c">Cash</div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="flex justify-between items-center pad816" >
                        <div class="w-30 pad816">Cash</div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                    </div>
                    <div class="flex justify-between items-center pad816">
                        <div class="w-30 pad816">Petty Cash</div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                    </div>
                </section>
                <section class="secPad">
                    <div class="flex justify-between items-center pad816">
                        <div class="w-30 pad816 b" style="color: #132c8c">Current Asset</div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="flex justify-between items-center pad816">
                        <div class="w-30 pad816">Cash</div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                    </div>
                    <div class="flex justify-between items-center pad816">
                        <div class="w-30 pad816">Petty Cash</div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                    </div>
                </section>
                <section class="secPad">
                    <div class="flex justify-between items-center pad816">
                        <div class="w-30 pad816 b" style="color: #132c8c">Fixed Asset</div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="flex justify-between items-center pad816">
                        <div class="w-30 pad816">Cash</div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                    </div>
                    <div class="flex justify-between items-center pad816">
                        <div class="w-30 pad816">Petty Cash</div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                        <div class="w-30 pad816">
                            <input type="number" value="0" class="OBInput pad816" />
                        </div>
                    </div>
                </section>
                <section class="secPad"  >
                    <div class="flex justify-between items-center" style="padding: 10px 16px">
                        <div class="w-30 pad816 tr"  >Total</div>
                        <div class="w-30 pad816"  ><span class="pl3">₦0.00</span></div>
                        <div class="w-30 pad816"  ><span class="pl3">₦0.00</span></div>
                    </div>
                </section>
            </div>
        </template>
    </chart-of-account-wrapper>
</template>
<script>
import ChartOfAccountWrapper from '@/views/chartOfAccount/widgets/ChartOfAccountWrapper'
export default {
    name: 'OpeningBalance',
    components: { ChartOfAccountWrapper },
    setup() {
        return {}
    },
}
</script>
<style scoped>
.pad816 {
    padding: 0.5rem 1rem;
    width: 100%;
}
.OBInput {
    border-radius: 4px;
    border: 1px solid #e3e8ee;
    background: rgba(244, 244, 244, 0.7);
    width: 80%;
}
@media screen and (max-width: 667px) {
    .OBInput {
        padding: 0.5rem;
    }
    .mbNone{
        display: none;
    }
}
</style>
